<template>
  <div class="w-100 button" :class="{ highlight: isHighlighted }">
    <span class="name">{{ name }}</span>
  </div>
</template>
<script>
export default {
  name: 'Button',
  props: ['name', 'isHighlighted'],
};
</script>

<style lang="less" scoped="true">
@import '@/assets/lessjs/variables.less';
.button {
  background-color: @dark-grey;
  color: white;
  border: none;
  text-align: center;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
  .name {
    text-decoration: none !important;
  }
  &.highlight {
    background-color: @highlight;
    color: black;
    &:hover {
      background-color: darken(@highlight, 10%);
    }
  }

  &.disabled {
    background-color: darken(@highlight, 30%);
    color: grey;
    pointer-events: none;
  }

  &:hover {
    background-color: darken(@dark-grey, 10%);
    text-decoration: none !important;
  }
}
</style>
